
<template>
  <div class="Fince_Cw_D">
    <div class="tab_Top">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect">
        <el-menu-item index="1">工资单</el-menu-item>
        <el-menu-item index="2">账单核对</el-menu-item>
        <el-menu-item index="3">资金管理</el-menu-item>
        <el-menu-item index="4">发票管理</el-menu-item>
      </el-menu>
      <div class="Men_1">
        说明：设置考勤部门与人员、考勤班次、考勤方式（Wi-Fi）等相关规则，点击下方添加考勤进入按步骤设置即可。
      </div>
      <!-- <div class="Men_1">班次设置：不同班次有不同的上班时间、下班时间、午休时间以及迟到多久算严重等相关信息。</div> -->
      <!-- <div class="Men_1">考勤组设置：设置考勤部门与人员、考勤班次、考勤方式（Wi-Fi）等相关规则，点击下方添加考勤进入按步骤设置即可。</div>
      <div class="Men_1">考勤组设置：设置考勤部门与人员、考勤班次、考勤方式（Wi-Fi）等相关规则，点击下方添加考勤进入按步骤设置即可。</div> -->
    </div>

    
    <div>
      <div class="Tables" style="padding-bottom:18px">
        <div class="Trys" style="margin-right:500px">
          <div class="uiius" style="margin-top:10px">发票类型：</div> 
          <div class="uiius" v-for="(item, i) in Nature" :key="i" :class="P_actitA_1 == i ? 'sddsdd': ''" 
            style="margin-left:10px;margin-top:8px" @click="P_AUOP1(i)">{{item}}</div>
        </div>
        <div class="Trys" style="margin:15px 500px 10px 0">
          <div class="uiius" style="margin-top:8px">凭证类型：</div> 
          <div class="uiius" v-for="(item, i) in Invoice_type" :key="i" :class="P_actitA_2 == i? 'sddsdd': ''" 
            style="margin-left:10px" @click="P_AUOP2(i)">{{item}}</div>
        </div>   
        <div class="Trys" style="margin:5px 500px 10px 0">
          <div class="uiius" style="margin-top:8px">开票状态：</div> 
          <div class="uiius" v-for="(item, i) in Invoice_status" :key="i" :class="P_actitA_3 == i ? 'sddsdd': ''" 
            style="margin-left:10px" @click="P_AUOP3(i)">{{item}}</div>
        </div>
        <div class="Trys Tiii" style="margin-right:580px;margin-top:15px">
          <div class="uiius" style="margin: 2px 0 0 0px;">转出日期：</div>
          <el-date-picker v-model="Months111" type="date" placeholder="选择日期" :clearable="false"></el-date-picker>
          <span style="margin: 5px 15px;">至</span>
          <el-date-picker v-model="Months222" type="date" placeholder="选择日期" :clearable="false"></el-date-picker>

          <el-button style="margin-left:15px" size="small" type="primary" @click="Btn_chaxun(3)">查询</el-button>
          <el-button size="small" @click="Btn_chongzhi">重置</el-button>
        </div>
      </div>
      <div class="Tables"> 
        <div class=" Tab_y" @click="Staff_del3(3)" style="border:1px solid #FF4D4F;color:#FF4D4F;margin-bottom: 24px;"><i class="el-icon-delete" style="color:#FF4D4F"></i>批量删除</div>
        <div class="dsds" style="width: 30%;float: right;">
          <div class="el_seso"><i class="el-icon-search" @click="inpu_sech()"></i></div>
          <el-input v-model="input_seach" placeholder="请输入内容" @keyup.enter.native="inpu_sech()"></el-input>
        </div>

        <el-table
          :data="tableData3"
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="select_change3">
          <el-table-column type="selection"  width="55"  align="center"></el-table-column>
          <el-table-column prop="id"  label="ID" width="55" align="center"></el-table-column>
          <el-table-column align="center" prop="user.nickname" label="申请人" width="140"></el-table-column>
          <el-table-column align="center" prop="info.invoice_type_text" label="发票类型" width="150"></el-table-column>
          <el-table-column align="center" prop="nature_text" label="凭证类型" width="150"></el-table-column>
          <el-table-column align="center" prop="apply_time" label="申请日期" width="150"></el-table-column>
          <el-table-column align="center" prop="price" label="开票金额" width="150"></el-table-column>
          <el-table-column align="center" prop="bill_time" label="开票日期" width="150"></el-table-column>
          <el-table-column align="center" prop="invoice_status_text" label="开票状态" width="150"></el-table-column>
          <el-table-column align="center" label="操作" >
            <template #default="scope">
              <div class="el_butto" style="margin-right:12px">
                  <el-button
                  type="text" 
                  >批复</el-button>
                   <!-- @click="handEdit4(scope.row)" -->
              </div>
              <div class="el_butto">
                  <el-button type="text" class="red" @click="handEdit3(2, scope.row)" v-if="TextMoMo('order/invoice:read')">查看</el-button>
                  <el-button type="text" class="red" v-if="!TextMoMo('order/invoice:read')">查看</el-button>
              </div>
              <div class="el_butto" style="margin-left:15px">
                  <el-button type="text" class="red" @click="handEdit3(1, scope.row)" v-if="TextMoMo('order/invoice:edit')">编辑</el-button>
              </div>
              <div class="el_butto rere" style="margin-left:15px">
                  <el-button
                  type="text"
                  class="red" v-if="TextMoMo('order/invoice:del')"
                  @click="handDelete3(scope.row)"
                  >删除</el-button>
              </div>

            </template>
          </el-table-column>
        </el-table>
        <div class=""></div>
        <div class="block">
          <el-pagination
            background
            @current-change="handChange3"
            :current-page="currentPage3"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total3">
          </el-pagination>
        </div>
      </div>
    </div>

    <!--查看发票-->
    <div ref="rightPanel" v-if="show2" class="rightPanel-container show">
          <div class="rightPanel-background" />
          <div style="height:1100px;width: 680px;position: fixed;top: 0;right: 0;z-index:999;" >
            <el-scrollbar style="height:100%">
              <div class="rightPanel" style="height:1100px">
                <div class="Rig_1">发票操作<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
                <div style="margin:0 32px" class="sadasd">
                  <table width="600" border="1" cellspacing="0" cellpadding="0"  border-collapse:collapse  bordercolor="#D9D9D9"> 
                  <tr> 
                    <td width="105">申请人</td> 
                    <td width="181">{{el_table_Data_user.nickname}}</td> 
                    <td width="112">订单信息</td> 
                    <td width="112">{{el_table_Data_order.order_no}}</td> 
                  </tr> 
                  <tr> 
                    <td>开票金额</td> 
                    <td>{{el_table_Data_row.price}}</td> 
                    <td>发票类型</td> 
                    <td>{{el_table_Data_info.invoice_type_text}}</td> 
                  </tr> 
                  <tr> 
                    <td>凭证类型</td> 
                    <td>{{el_table_Data_row.nature_text}}</td> 
                    <td>申请时间</td> 
                    <td>{{el_table_Data_row.apply_time}}</td> 
                  </tr> 
                  <tr> 
                    <td colspan="2">邮寄地址</td> 
                    <td colspan="2" v-if="el_table_Data_address != null">{{el_table_Data_address.province}}{{el_table_Data_address.city}}{{el_table_Data_address.town}}{{el_table_Data_address.street}}</td> 
                    <td colspan="2" v-if="el_table_Data_address == null">暂无地址</td> 
                  </tr> 
                  </table>  
                  <div>
                    <div style="font-size: 14px;font-weight: bold;color: #262626;margin:20px 0">开票情况：</div>

                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D Rl_nput">
                                <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 开票状态：</div>
                                <el-select v-model="Fond_tag" placeholder="请选择" @change="Chang_ses2">
                                    <el-option v-for="item in MMD" :key="item.id" :label="item.name" :value="item.id"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div style="width:50%;float:left">
                            <div class="Rig_D Rl_nput">
                                <div class="Rig_Ddd" style="margin-bottom:9px;width:100%">
                                  <span style="color:#FF4D4F;float:left">* </span>
                                  <span style="float:left;color:#333333!important">开票时间：</span>
                                </div>
                                <el-date-picker v-model="Kaipiao_time" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" style="width:90%"></el-date-picker>
                            </div>
                        </div>
                    </div>
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D Rl_nput">
                                <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 开票金额：</div>
                                <el-input v-model="Kaipiao_price" placeholder="请输入部门" class="El_ii" @focus="Huoqu" style="width:90%"> </el-input>
                            </div>
                        </div>
                        <div style="width:50%;float:left">
                            <div class="Rig_D Rl_nput">
                                <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 开票人：</div>
                                <el-select v-model="Kaipiao_peop" placeholder="请选择开票人" @change="Chang_ses2">
                                    <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div>
                       <div style="font-size: 14px;font-weight: bold;color: #262626;margin:20px 0">查看发票：</div>
                      <div class="Rtty" style="clear:both">
                        <img class="Rtt_im22g" v-for="item in full_avatar" :key="item" :label="item" :value="item" :src="item"   />
                        <div style="margin-top:20px">
                          <el-upload
                            
                            :action="upload"
                            :headers="heads"
                            list-type="picture-card"
                            :on-success="UP_Success"
                            :on-change = "Up_chang"
                            :on-remove="handleRemove"
                            :on-preview="handlePictureCardPreview"
                            >
                            <i class="el-icon-plus"></i>
                            <div class="el_asff">上传图片</div>
                          </el-upload>
                        </div>
                      </div>
                    </div>
                      <div class="BCSZ" @click="Staff_Add()" style="margin-right:0px;margin-left:20px">完成</div>
                      <div class="BCSZ" @click="show2 = false"  style="background:#fff;color:#595959;border: 1px solid #D9D9D9;cursor: pointer;">取消</div>
                  </div>
                </div>
             
                </div>
            </el-scrollbar>
          </div>
    </div>
    <!--查看发票-->

    <!--查看发票-->
    <div ref="rightPanel" v-if="show22" class="rightPanel-container show">
          <div class="rightPanel-background" />
          <div style="height:1100px;width: 680px;position: fixed;top: 0;right: 0;z-index:999;" >
            <el-scrollbar style="height:100%">
              <div class="rightPanel" style="height:1100px">
                <div class="Rig_1">查看发票<i class="el-icon-close" @click="show22 = false" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
                <div style="margin:0 32px" class="sadasd">
                  <table width="600" border="1" cellspacing="0" cellpadding="0"  border-collapse:collapse  bordercolor="#D9D9D9"> 
                  <tr> 
                    <td width="105">申请人</td> 
                    <td width="181">{{el_table_Data_user.nickname}}</td> 
                    <td width="112">订单信息</td> 
                    <td width="112">{{el_table_Data_order.order_no}}</td> 
                  </tr> 
                  <tr> 
                    <td>开票金额</td> 
                    <td>{{el_table_Data_row.price}}</td> 
                    <td>发票类型</td> 
                    <td>{{el_table_Data_info.invoice_type_text}}</td> 
                  </tr> 
                  <tr> 
                    <td>凭证类型</td> 
                    <td>{{el_table_Data_row.nature_text}}</td> 
                    <td>申请时间</td> 
                    <td>{{el_table_Data_row.apply_time}}</td> 
                  </tr> 
                  <tr> 
                    <td colspan="2">邮寄地址</td> 
                    <td colspan="2" v-if="el_table_Data_address != null">{{el_table_Data_address.province}}{{el_table_Data_address.city}}{{el_table_Data_address.town}}{{el_table_Data_address.street}}</td> 
                    <td colspan="2" v-if="el_table_Data_address == null">暂无地址</td> 
                  </tr> 
                  </table>  
                  <div>
                    <div style="font-size: 14px;font-weight: bold;color: #262626;margin:20px 0">开票情况：</div>

                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D Rl_nput">
                                <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 开票状态：</div>
                                <el-select v-model="Fond_tag" placeholder="请选择" @change="Chang_ses2" disabled>
                                    <el-option v-for="item in MMD" :key="item.id" :label="item.name" :value="item.id"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div style="width:50%;float:left">
                            <div class="Rig_D Rl_nput">
                                <div class="Rig_Ddd" style="margin-bottom:9px;width:100%">
                                  <span style="color:#FF4D4F;float:left">* </span>
                                  <span style="float:left;color:#333333!important">开票时间：</span>
                                </div>
                                <el-date-picker v-model="Kaipiao_time" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" disabled style="width:90%"></el-date-picker>
                            </div>
                        </div>
                    </div>
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D Rl_nput">
                                <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 开票金额：</div>
                                <el-input v-model="Kaipiao_price" placeholder="请输入部门" class="El_ii" @focus="Huoqu" disabled style="width:90%"></el-input>
                            </div>
                        </div>
                        <div style="width:50%;float:left">
                            <div class="Rig_D Rl_nput">
                                <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 开票人：</div>
                                <el-select v-model="Kaipiao_peop" placeholder="请选择开票人" @change="Chang_ses2" disabled>
                                    <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id" ></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div>
                       <div style="font-size: 14px;font-weight: bold;color: #262626;margin:20px 0">查看发票：</div>
                      <div class="Rtty" style="clear:both">
                        <img class="Rtt_im22g" v-for="item in full_avatar" :key="item" :label="item" :value="item" :src="item"   />
                      </div>
                    </div>
                      <div class="BCSZ" @click="show22 = false"  style="background:#fff;color:#595959;border: 1px solid #D9D9D9;cursor: pointer;">取消</div>
                  </div>
                </div>
             
                </div>
            </el-scrollbar>
          </div>
    </div>
    <!--查看发票-->
  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue"; 
export default {
  name: "basetable",
  data() {
    return {
      actit:0,
      actit2:0,
      actit3:1,
      P_actitA_1:0,
      P_actitA_2:0,
      P_actitA_3:0,
      MMD:[{id:1,name:"待批复"},{id:2,name:"同意开票"},{id:3,name:"拒绝申请"},{id:4,name:"已开票"}],
      Fond_tag:null,
      Kaipiao_time:'',
      Kaipiao_price:'',
      Kaipiao_peop:'',

      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},

      Row_Eurl:[], //图片上传成功存储

      full_avatar:[],
      full_avatar2:[],

      Months111:'',
      Months1:'',
      Months222:'',
      Months22:'',
      Months2:'',
      fapiao_id:0,

      activeIndex: "4",

      ionini:'',
      staff_id:0,
      ED_textarea:'',
      el_table_Data:[],
      el_table_Data_order:{},
      el_table_Data_row:{},
      el_table_Data_user:{},
      el_table_Data_address:null,
      bankList:[],
      el_table_Data_realname:'',


      StaffList:'',

      StaFFList:[],
      
      input_seach:'',
      Bumen_Xinj1:null,

      People_jj1:'',
      People_jj2:'',
      People_jj3:'',
      People_jj4:'',

      People_jj_y_day:'',
      People_jj_s_day:'',
      People_jj_s_jb:'',

      People_jj_s_j1:'',
      People_jj_s_j2:'',
      People_jj_s_j3:'',
      People_jj_s_j4:'',
      People_jj_s_j5:'',
      People_jj_s_j6:'',
      People_jj_s_j7:'',
      People_jj_s_j8:'',
      People_jj_s_j9:'',
      People_jj_s_j10:'',
      People_jj_s_j11:'',
      People_jj_s_j12:'',

      
      DepartmentList:[],
      StaffList:[],
      Parents:[],
      show2: false,
      show22: false,

      XX:'xin',
      department_id:0,

      editVisible:false,
      editVisible1:false,
      editVisible2:false,
      input: "",
      
      tableData: [],
      tableData2: [],
      tableData3:[],
      total:0,
      total2:0,
      total3:0,
      currentPage1:0,
      currentPage2:0,
      currentPage3:0,
      multipleSelection: [],
      selection_arr2:[],     // 资金管理选中
      selection_arr3:[],     // 资金管理选中
      id: -1,
      Row:{},
      Crojj:{
        pay_type: "redpack", //转账类型，示例：redpack=红包，transfer=零钱，paybank=银行卡
        password: "123456789", //密码，示例：123456789
        realname: "张三", //收款人姓名
        // openid: "asdadsdsadasdsd", //收款人openid，类型为红包和零钱时必填
        bank_card: "50000000000000000000", //银行卡号-类型为银行卡时必填
        bank_open: "工商银行", //开户行-类型为银行卡时必填
        money: 100, //转账金额
        reason: "采购", //打款事由
        remark: "" //备注
      },
      el_table_Data_info:{},
      Nature:{},
      Invoice_type:{},
      Invoice_status:{},
    };
  },
  mounted() {
    var that = this

    that.CCCCC()
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    axios.get('/admin/ajax/staffList',{   // 全部员工列表
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else{ 
              that.StaFFList = res.data.data
              console.log(that.StaFFList)
          }
    })  
    axios.get('/admin/order/invoice/statisticsColumns',{  
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else{ 
              that.Nature = res.data.data.nature
              that.Invoice_type = res.data.data.invoice_type
              that.Invoice_status = res.data.data.invoice_status
              that.Nature[0] = '全部'
              that.Invoice_type[0] = '全部'
              that.Invoice_status[0] = '全部'
          }
    }) 
  },
  methods: {
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    Close() {
      this.dialogVisible3 = false;
      this.dialogVisible4 = false;
      this.dialogVisible5 = false;
      this.dialogVisible6 = false;
      this.dialogVisible7 = false;
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
    },
    Add_kq(e) {  //
        var that = this
        if (e == 1) {
          that.editVisible = true
          that.Crojj = {}
          that.Crojj.pay_type = 'redpack'
        } else if (e == 2) {
          that.show1 = true
        }
    },

    P_AUOP1(e) {
      var that = this
      if (e == 0 ){
        that.P_actitA_1 = e      
      } else {
        that.P_actitA_1 = e  
      }
      
      that.Btn_chaxun()
    },
    P_AUOP2(e) {
      var that = this
      that.P_actitA_2 = e    
      that.Btn_chaxun()  
    },
    P_AUOP3(e) {
      var that = this
      that.P_actitA_3 = e   
      that.Btn_chaxun()  
    },


    UP_Success(e) {  //图片上传成功
      var that = this
      console.log(e)
      that.Row_Eurl.push(e.data.url)
      that.aTY = 0
      console.log(that.Row_Eurl)
    },

    handleRemove(file, fileList) {
      var that = this
      that.Row_Eurl = []
        console.log(fileList)
        for (let i = 0; i < fileList.length; i++) {
          const element = fileList[i];
          that.Row_Eurl.push(element.response.data.url)
         
        }
        console.log(that.Row_Eurl)
    },
    handlePictureCardPreview(file) {   //获取图片
      var that = this
      that.full_image = file.url;
    },

    handleSelect(key, keyPath) {               ////////////////////////////////////
      var that = this
      console.log(key, keyPath);
      that.input_seach = ''
      that.actit = 0
      that.actit2 = 0
      that.actit3 = 1
      that.Months1 = ''
      that.Months22 = ''
      that.Months2 = ''
      if (key == 1) {
        that.$router.push('/Workbench/Fince_cw')
      } else if(key == 2){
        that.$router.push('/Workbench/Fince_cw_B')
      } else if (key == 3) {
        that.$router.push('/Workbench/Fince_cw_C')
      } else if (key == 4) {
        that.$router.push('/Workbench/Fince_cw_D')
      }
    },
    inpu_sech(e) {   // 搜索材料
        var that = this
          that.currentPage3 = 0,
          axios({
            method: 'post',
            url: '/admin/order/invoice/index',    
            data: {
              page: 1,
              offset:0,
              limit:10,
              filter:{
                  keyword:that.input_seach
              }
            },
          }).then(function (res) {
                that.total3 = res.data.data.total
                that.tableData3 = res.data.data.rows
              
          })
    }, 



    handDelete3(e) {       // 删除发票
        var that = this
        var arr  = []
        arr.push(e.id)
        that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
        axios({
            method: 'delete',
            url: '/admin/order/invoice/del',
            data: {
              ids: arr
            },
          }).then(function (res) {
            that.$message.success(res.data.msg);
            axios({ 
              method: 'post',
              url: '/admin/order/invoice/index',    
              data: {
                page: that.currentPage3,
                offset:0,
                limit:7,
              },
            }).then(function (res) {
                if (res.code == 401) {
                  that.$router.push('/Login');
                } else {
                  that.total3 = res.data.data.total
                  that.tableData3 = res.data.data.rows
                }

            })
          })                 
        })
    },
    // 多选操作
    handleSelectionChange(val) {
        this.multipleSelection = val;
    },

    Btn_chongzhi() {    //重置
      var that = this
      that.Months22 = '',
      that.StaffList = [],
      that.input_seach = ''
      that.actit = 0
      that.actit2 = 0
      that.Months1 = ''
      that.Months2 = ''
      that.P_actitA_1 = 0
      that.P_actitA_2 = 0
      that.P_actitA_3 = 0
    },

    Btn_chaxun(e) {        //查询
        var that = this
        var time = ''
        var time2 = ''
          if (that.Months111 != '' ) {
            var date = new Date(that.Months111)
            
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            m = m < 10 ? ('0' + m) : m
            var d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            time = y + '-' + m + '-' + d
          }
          if (that.Months222 != '') {
            var date2 = new Date(that.Months222)
            var y2 = date2.getFullYear()
            var m2 = date2.getMonth() + 1
            m2 = m2 < 10 ? ('0' + m2) : m2
            var d2 = date2.getDate()
            d2 = d2 < 10 ? ('0' + d2) : d2
            time2 = y2 + '-' + m2 + '-' + d2
          }      
            axios({
              method: 'post',
              url: '/admin/order/invoice/index',    
              data: {
                page: 1,
                offset:0,
                limit:10,
                filter:{
                  invoice_type: that.P_actitA_2,
                   nature: that.P_actitA_1,
                  invoice_status: that.P_actitA_3,
                  time:{
                    min:time,
                    max:time2,
                  },
                  keyword:that.input_seach
                }
              },
            }).then(function (res) {
                if (res.code == 401) {
                  that.$router.push('/Login');
                } else {
                  that.total3 = res.data.data.total
                  that.tableData3 = res.data.data.rows
                }
            })    
    },

    handChange3(val) {     // 分页3
      var that = this
      that.currentPage3 = val
        axios({ 
          method: 'post',
          url: '/admin/order/invoice/index',    
          data: {
            page: val,
            limit:7,
            filter:{
                keyword:that.input_seach
            }
          },
        }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
                that.total3 = res.data.data.total
                that.tableData3 = res.data.data.rows
            }

        })
    },
    CCCCC() {
          var that = this
          axios({ 
            method: 'post',
            url: '/admin/order/invoice/index',    
            data: {
              page: 1,
              offset:0,
              limit:10,
            },
          }).then(function (res) {
              if (res.code == 401) {
                 that.$router.push('/Login');
              } else {
                that.total3 = res.data.data.total
                that.tableData3 = res.data.data.rows
              }

          })
    },

    Staff_Add() {        //  确认添加 编辑完成
      var that = this
        var cc = []
        if (that.full_avatar2 == undefined) {
          that.full_avatar2 = []
        } else { 
          console.log(that.full_avatar2)
          var ccc = that.full_avatar2.concat(that.Row_Eurl)
        }

        var time = ''
        var date = new Date(that.Kaipiao_time)
        var y = date.getFullYear()
        var m = date.getMonth() + 1
        m = m < 10 ? ('0' + m) : m
        var d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        time = y + '-' + m + '-' + d
        axios({ 
          method: 'put',
          url: '/admin/order/invoice/edit',    
          data: {
            id:that.fapiao_id,
            row:{
              bill_money: that.Kaipiao_price, //开票金额
              invoice_status: that.Fond_tag, //开票状态
              bill_time: time, //开票时间
              drawer_id: that.Kaipiao_peop, //开票人，员工ID
              images: ccc //发票图片
            }
          },
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
                that.show2 = false
                that.$message.success(res.data.msg);
                that.CCCCC()

            }

        })
 
    },

    handEdit3(e, t) {      //发票管理查看
      var that = this
      if (e == 1) {
        that.show2 = true
      } else if (e ==2) {
        that.show22 = true
      }
      that.fapiao_id = t.id
      axios.get('/admin/order/invoice/read',{   // 模块
        params:{
            id:t.id
        }
        }).then(function (res) {
          that.full_avatar = res.data.data.row.imgs_url
          that.full_avatar2 = res.data.data.row.images

          
          that.el_table_Data_row = res.data.data.row

          that.el_table_Data_user = res.data.data.user




          that.Fond_tag = res.data.data.row.invoice_status      //开票状态

          that.Kaipiao_time =  res.data.data.row.bill_time      //开票时间
          that.Kaipiao_price =  res.data.data.row.bill_money    //开票金额
          if(res.data.data.drawer) {
            that.Kaipiao_peop =  res.data.data.drawer.realname
          }
          if (res.data.data.order) {
            that.el_table_Data_order = res.data.data.order
          }
          if (res.data.data.info) {
            that.el_table_Data_info = res.data.data.info
          }
          if (res.data.data.address) {
            that.el_table_Data_address = res.data.data.address
          }
          // that.el_table_Data_realname = res.data.data.row.staff.realname
      })
      
    },

    select_change3(sel) {   // 发票勾选
      var that = this
      var searr = []
      for (let i = 0; i < sel.length; i++) {
        const ele = sel[i].id;
        searr.push(ele)
      }
      that.selection_arr3 = searr

    },
    Staff_del3(e) {   // 批量删除
      var that = this
        that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
          if (e == 2) {
            var arr = []
            for (let i = 0; i < that.selection_arr2.length; i++) {
              const element = that.selection_arr2[i];
              arr.push(element)
            }
            axios.delete('/admin/finance/withdraw/del',{   // 模块
              params:{
                    ids: arr
              }
            }).then(function (res) {
            if (res.code == 0) {
                  that.$message.warning(res.data.msg);
            } else{ 
                that.$message.success(res.data.msg);
                axios({ 
                  method: 'post',
                  url: '/admin/finance/withdraw/index',    
                  data: {
                    page: that.currentPage2,
                    offset:0,
                    limit:7,
                  },
                }).then(function (res) {
                    if (res.code == 401) {
                      that.$router.push('/Login');
                    } else {
                      that.total2 = res.data.data.total
                      that.tableData2 = res.data.data.rows
                      that.bankList = res.data.data.bankList
                    }

                })
            }
          })
          } else if(e == 3) {
            console.log(that.selection_arr3)
            var arr = []
            for (let i = 0; i < that.selection_arr3.length; i++) {
              const element = that.selection_arr3[i];
              arr.push(element)
            }
            console.log(arr)
            axios.delete('/admin/order/invoice/del',{   // 模块
              params:{
                    ids: arr
              }
              }).then(function (res) {
              if (res.code == 0) {
                    that.$message.warning(res.data.msg);
              } else{ 
                  that.$message.success(res.data.msg);
                    axios({ 
                      method: 'post',
                      url: '/admin/order/invoice/index',    
                      data: {
                        page: that.currentPage3,
                        offset:0,
                        limit:7,
                      },
                    }).then(function (res) {
                        if (res.code == 401) {
                          that.$router.push('/Login');
                        } else {
                          that.total3 = res.data.data.total
                          that.tableData3 = res.data.data.rows
                        }

                    })
              }
            })            
          }

        })
    },
  
  },
};
</script>

<style scoped>  
    
.Fince_Cw_D .uiius {
  display: inline-block;
  padding:4px 12px;
  height: 25px;
  line-height: 25px;
  background: #fff; 
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #262626;
  margin-top: 6px;
  cursor: pointer;
}
.Fince_Cw_D .sddsdd {
  background: #0081ff;
  color: #fff!important;
  display: inline-block;
  padding: 4px 12px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  border-radius: 5px;
  cursor: pointer;
}  
.Fince_Cw_D .sadasd table td{
  background:#FFF;
  padding-left: 10px;
  height: 36px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #595959;
  } 
 .Fince_Cw_D .Rtt_im22g {
   width: auto;
   height: 150px;
   margin-right: 20px;
  }
</style>
